exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magazines-js": () => import("./../../../src/pages/magazines.js" /* webpackChunkName: "component---src-pages-magazines-js" */),
  "component---src-posts-another-blog-mdx": () => import("./../../../src/posts/another-blog.mdx" /* webpackChunkName: "component---src-posts-another-blog-mdx" */),
  "component---src-posts-no-05-el-confinamiento-mdx": () => import("./../../../src/posts/no-05/el-confinamiento.mdx" /* webpackChunkName: "component---src-posts-no-05-el-confinamiento-mdx" */),
  "component---src-posts-no-05-francisco-trejo-mdx": () => import("./../../../src/posts/no-05/francisco-trejo.mdx" /* webpackChunkName: "component---src-posts-no-05-francisco-trejo-mdx" */),
  "component---src-posts-no-05-haiti-mdx": () => import("./../../../src/posts/no-05/haiti.mdx" /* webpackChunkName: "component---src-posts-no-05-haiti-mdx" */),
  "component---src-posts-no-05-las-peliculas-que-el-2021-se-llevo-mdx": () => import("./../../../src/posts/no-05_las-peliculas-que-el-2021-se-llevo.mdx" /* webpackChunkName: "component---src-posts-no-05-las-peliculas-que-el-2021-se-llevo-mdx" */),
  "component---src-posts-no-05-leo-hernandez-mdx": () => import("./../../../src/posts/no-05/leo-hernandez.mdx" /* webpackChunkName: "component---src-posts-no-05-leo-hernandez-mdx" */),
  "component---src-posts-no-05-maternidades-contrastantes-mdx": () => import("./../../../src/posts/no-05/maternidades-contrastantes.mdx" /* webpackChunkName: "component---src-posts-no-05-maternidades-contrastantes-mdx" */),
  "component---src-posts-no-05-nuestras-cartas-mdx": () => import("./../../../src/posts/no-05/nuestras-cartas.mdx" /* webpackChunkName: "component---src-posts-no-05-nuestras-cartas-mdx" */),
  "component---src-posts-no-4-carla-valdespino-mdx": () => import("./../../../src/posts/no-4/carla-valdespino.mdx" /* webpackChunkName: "component---src-posts-no-4-carla-valdespino-mdx" */),
  "component---src-posts-no-4-el-corazon-se-mide-como-el-mar-mdx": () => import("./../../../src/posts/no-4/el-corazon-se-mide-como-el-mar.mdx" /* webpackChunkName: "component---src-posts-no-4-el-corazon-se-mide-como-el-mar-mdx" */),
  "component---src-posts-no-4-guadalupe-muriel-mdx": () => import("./../../../src/posts/no-4/guadalupe-muriel.mdx" /* webpackChunkName: "component---src-posts-no-4-guadalupe-muriel-mdx" */),
  "component---src-posts-no-4-que-es-la-literatura-del-caribe-mdx": () => import("./../../../src/posts/no-4/que-es-la-literatura-del-caribe.mdx" /* webpackChunkName: "component---src-posts-no-4-que-es-la-literatura-del-caribe-mdx" */),
  "component---src-posts-no-4-selva-tragica-mdx": () => import("./../../../src/posts/no-4/selva-tragica.mdx" /* webpackChunkName: "component---src-posts-no-4-selva-tragica-mdx" */),
  "component---src-posts-no-4-tulum-el-pueblo-cosmopolita-y-snob-mdx": () => import("./../../../src/posts/no-4/tulum-el-pueblo-cosmopolita-y-snob.mdx" /* webpackChunkName: "component---src-posts-no-4-tulum-el-pueblo-cosmopolita-y-snob-mdx" */),
  "component---src-posts-no-4-tulum-el-puerto-maya-amurallado-mdx": () => import("./../../../src/posts/no-4/tulum-el-puerto-maya-amurallado.mdx" /* webpackChunkName: "component---src-posts-no-4-tulum-el-puerto-maya-amurallado-mdx" */)
}

